<template>
    <div>
        <div class="content-wrapper">
            <div class="row row-inner-nicely">
                <div class="col-6"><a :class="getTipsHeaderClass('Top místa')" href="#" data-target=".js-list-tips-places" :title="$t('top_places.header')"><h2 class="h4">{{ $t('top_places.header') }}</h2></a></div>
                <div class="col-6"><a :class="getTipsHeaderClass('Aktuality')" href="#" data-target=".js-list-tips-news" :title="$t('news.header')"><h2 class="h4">{{ $t('news.header') }}</h2></a></div>

                <div :class="getContentClass('Top místa')">
                    <div class="row align-content-stretch row-inner-nicely">
                        <div class="col-sm-4 col-md-4 margin-top-20-t-10" v-for="item in featuredItems" v-if="featuredItems.length > 0">
                            <div class="box">
                                <div v-if="item.mainImage.formats !== undefined">
                                    <a v-bind:href="'/detail/' + item.slug" class="image-cover" v-bind:title="item.mainImage.formats[3].alt" v-bind:style="getItemImageBackground(item)" />
                                </div>
                                <div class="box-content">
                                    <div class="box-text-wrap">
                                        <a v-bind:href="localePath + '/detail/' + item.slug" class="header" v-bind:title="item.name"><h2 class="h5 weight-600">{{item.name}}</h2></a>
                                        <p class="date" v-if="item.parent.dateFrom"><b>{{item.parent.dateFrom}}</b><b v-if="item.parent.dateTo"> - {{item.parent.dateTo}}</b></p>
                                        <div class="perex" v-html="item.perex" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 margin-top-20-t-10" v-if="featuredItems.length === 0">
                            <p>{{ $t('top_places.not_found') }}</p>
                        </div>

                        <div class="col-sm-12 margin-top-20-t-10" v-if="featuredItems.length > 0">
                            <div class="text-center">
                                <a :href="$t('top_places.more_url')" class="button" :title="$t('top_places.more')">{{ $t('top_places.more') }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="getContentClass('Aktuality')">
                    <div class="row align-content-stretch row-inner-nicely">
                        <div class="col-sm-4 col-md-4 margin-top-20-t-10" v-for="news in latestNews" v-if="latestNews.length > 0">
                            <div class="box">
                                <div v-if="news.mainImage.formats !== undefined">
                                    <a v-bind:href="localePath + '/detail/' + news.slug" class="image-cover" v-bind:title="news.mainImage.formats[3].alt" v-bind:style="getItemImageBackground(news)" />
                                </div>
                                <div class="box-content">
                                    <div class="box-text-wrap">
                                        <a v-bind:href="localePath + '/detail/' + news.slug" class="header" v-bind:title="news.name"><h2 class="h5 weight-600">{{news.name}}</h2></a>
                                        <div class="perex" v-html="news.perex" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 col-md-4 margin-top-20-t-10" v-if="latestNews.length === 0">
                            <p>{{ $t('news.not_found') }}</p>
                        </div>

                        <div class="col-sm-12 margin-top-20-t-10" v-if="latestNews.length > 0">
                            <div class="text-center">
                                <a :href="$t('news.more_url')" class="button" :title="$t('news.more')">{{ $t('news.more') }}</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'homepageArticles',

        async mounted() {
            await axios({
                url: this.localePath + '/api/featuredItems',
                method: 'get',
            }).then((response) => {
                this.featuredItems = response.data;
            }).catch((error) => {
                console.log(error.response);
            });

            await axios({
                url: this.localePath + '/api/latestNews',
                method: 'get',
            }).then((response) => {
                this.latestNews = response.data;
            }).catch((error) => {
                console.log(error.response);
            });
        },

        data() {
            return {
                featuredItems: [],
                latestNews: [],
                variables: {},
            };
        },

        methods: {
            getItemImageBackground: function (item) {
                return 'background-image: url(\'' + item.mainImage.formats[3].src + '\');';
            },

            getTipsHeaderClass: function (headerName) {
                let headerClass = 'tips-header js-tips-header';

                if (this.initialTop === headerName) {
                    headerClass = headerClass + ' active';
                }

                return headerClass;
            },

            getContentClass: function (contentName) {
                let contentClass = 'col-sm-12 list-tips js-list-tips';

                if (contentName === 'Aktuality') {
                    contentClass = contentClass + ' js-list-tips-news';
                }

                if (contentName === 'Top místa') {
                    contentClass = contentClass + ' js-list-tips-places';
                }

                contentClass = contentClass + ' visible';

                if (this.initialTop === contentName) {
                    contentClass = contentClass + ' active';
                }

                return contentClass;
            }

        },

        props: [
            'initialTop',
            'localePath',
        ],

    }
</script>