<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="hp-header" v-html="$t('hp_header')"></h1>

                <homepageFilter v-bind:initial-circle="initialCircle" v-bind:locale-path="localePath" />

                <homepageArticles v-bind:initial-top="initialTop" v-bind:locale-path="localePath" />

            </div>
        </div>
    </div>
</template>

<script>
    import homepageFilter from "./homepageFilter";
    import homepageArticles from "./homepageArticles";
    import i18n from "../i18n";

    export default {
        name: 'homepage',

        components: {
            homepageFilter,
            homepageArticles
        },

        methods: {
            initTranslation() {
                i18n.locale = this.locale;
            }
        },

        mounted(){
            this.initTranslation();
        },

        props: [
            'initialCircle',
            'initialTop',
            'locale',
            'localePath',
        ],

    }
</script>