var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("h1", {
            staticClass: "hp-header",
            domProps: { innerHTML: _vm._s(_vm.$t("hp_header")) },
          }),
          _vm._v(" "),
          _c("homepageFilter", {
            attrs: {
              "initial-circle": _vm.initialCircle,
              "locale-path": _vm.localePath,
            },
          }),
          _vm._v(" "),
          _c("homepageArticles", {
            attrs: {
              "initial-top": _vm.initialTop,
              "locale-path": _vm.localePath,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }