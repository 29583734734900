<template>
    <div>
        <div class="row align-content-stretch margin-top-on-mobile">
            <div class="col-3">
                <div class="circle-wrapper">
                    <a :href="$t('filter.event.list-url')" class="circle" :title="$t('filter.event.header')"><span class="item-wrap"><span class="icon icon-calendar"></span><br><span class="text">{{ $t('filter.event.header') }}</span></span></a>
                </div>
            </div>
            <div class="col-3">
                <div class="circle-wrapper">
                    <a href="#" class="circle js-circle" id="js-circle-poznavat" data-target=".js-list-selection-gettoknow" :title="$t('filter.discover.header')"><span class="item-wrap"><span class="icon icon-castle"></span><br><span class="text">{{ $t('filter.discover.header') }}</span></span></a>
                </div>
            </div>
            <div class="col-3">
                <div class="circle-wrapper">
                    <a href="#" class="circle js-circle" id="js-circle-relax-a-sport" data-target=".js-list-selection-relaxandsport" :title="$t('filter.relax_and_sport.header')"><span class="item-wrap"><span class="icon icon-bike"></span><br><span class="text">{{ $t('filter.relax_and_sport.header') }}</span></span></a>
                </div>
            </div>
            <div class="col-3">
                <div class="circle-wrapper">
                    <a href="#" class="circle js-circle" id="js-circle-sluzby" data-target=".js-list-selection-services" :title="$t('filter.services.header')"><span class="item-wrap"><span class="icon icon-coffee"></span><br><span class="text">{{ $t('filter.services.header') }}</span></span></a>
                </div>
            </div>
        </div>

        <form class="form" method="get" :action="$t('filter.button.show_url')">
            <div class="content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-gettoknow">
                <div class="row row-inner-nicely">
                    <div class="col-sm-12">
                        <div class="content">
                            <div class="row row-inner-nicely">
                                <div class="col-sm-12 text-center">
                                    <h2 class="h3">{{ $t('filter.discover.choose') }}</h2>
                                </div>

                                   <div class="row-column">
                                    <div class="column-item" v-for="tag in discoverTags">
                                        <div class="form-row form-row-checkbox">
                                            <div class="hover-underline label-with-count">
                                                <input v-bind:id="tag.slug" v-bind:name="tag.slug" type="checkbox">
                                                <label v-bind:for="tag.slug">{{tag.name}} <b>({{tag.itemCount}})</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-row form-row-submit text-center margin-bottom-0">
                                        <button class="button" type="submit" name="i" value="poznavat" :title="$t('filter.button.show')">{{ $t('filter.button.show') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-relaxandsport">
                <div class="row row-inner-nicely">
                    <div class="col-sm-12">
                        <div class="content">
                            <div class="row row-inner-nicely">
                                <div class="col-sm-12 text-center">
                                    <h2 class="h3">{{ $t('filter.relax_and_sport.choose') }}</h2>
                                </div>

                                <div class="row-column">
                                    <div class="column-item" v-for="tag in relaxAndSportTags">
                                        <div class="form-row form-row-checkbox">
                                            <div class="hover-underline label-with-count">
                                                <input v-bind:id="tag.slug" v-bind:name="tag.slug" type="checkbox">
                                                <label v-bind:for="tag.slug">{{tag.name}} <b>({{tag.itemCount}})</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-row form-row-submit text-center margin-bottom-0">
                                        <button class="button" type="submit" name="i" value="relax-a-sport" :title="$t('filter.button.show')">{{ $t('filter.button.show') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-services">
                <div class="row row-inner-nicely">
                    <div class="col-sm-12">
                        <div class="content">
                            <div class="row row-inner-nicely">
                                <div class="col-sm-12 text-center">
                                    <h2 class="h3">{{ $t('filter.services.choose') }}</h2>
                                </div>

                                <div class="row-column">
                                    <div class="column-item" v-for="tag in servicesTags">
                                        <div class="form-row form-row-checkbox">
                                            <div class="hover-underline label-with-count">
                                                <input v-bind:id="tag.slug" v-bind:name="tag.slug" type="checkbox">
                                                <label v-bind:for="tag.slug">{{tag.name}} <b>({{tag.itemCount}})</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-row form-row-submit text-center margin-bottom-0">
                                        <button class="button" type="submit" name="i" value="sluzby" :title="$t('filter.button.show')">{{ $t('filter.button.show') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'homepageFilter',

        async mounted() {

            await axios({
                url: this.localePath + '/api/tag',
                method: 'get',
            }).then((response) => {
                this.discoverTags = response.data['discover'];
                this.relaxAndSportTags = response.data['relax-and-sport'];
                this.servicesTags = response.data['services'];
            }).catch((error) => {
                console.log(error.response);
            });

            await this.activateInitialCircle();
        },

        data() {
            return {
                discoverTags: [],
                relaxAndSportTags: [],
                servicesTags: [],
                featuredEvents: [],
            };
        },

        methods: {
            getOpacityClass: function (isPastEvent) {
                if (isPastEvent) {
                    return 'box opacity-less';
                }

                return '';
            },

            activateInitialCircle: async function() {
                if (this.initialCircle !== '') {
                    let $el = $('#js-circle-' + this.initialCircle);
                    if ($el.length !== 0) {
                        $el.addClass('active');
                        let target = $el.attr('data-target');

                        $('.js-list-selection').removeClass('visible active');
                        $(target).addClass('active');
                        $(target).addClass('visible');
                    }
                }

            }
        },

        props: [
            'initialCircle',
            'localePath',
        ],

    }
</script>