import Vue from "vue";
// import VCalendar from 'v-calendar';
import i18n from './i18n';

import homepage from './components/homepage';

// Vue.use(VCalendar, {prefix: 'vc'});

new Vue({
    el: '#app',
    i18n,
    components: {
        homepage,
    },
});