var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row align-content-stretch margin-top-on-mobile" },
      [
        _c("div", { staticClass: "col-3" }, [
          _c("div", { staticClass: "circle-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "circle",
                attrs: {
                  href: _vm.$t("filter.event.list-url"),
                  title: _vm.$t("filter.event.header"),
                },
              },
              [
                _c("span", { staticClass: "item-wrap" }, [
                  _c("span", { staticClass: "icon icon-calendar" }),
                  _c("br"),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("filter.event.header"))),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c("div", { staticClass: "circle-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "circle js-circle",
                attrs: {
                  href: "#",
                  id: "js-circle-poznavat",
                  "data-target": ".js-list-selection-gettoknow",
                  title: _vm.$t("filter.discover.header"),
                },
              },
              [
                _c("span", { staticClass: "item-wrap" }, [
                  _c("span", { staticClass: "icon icon-castle" }),
                  _c("br"),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("filter.discover.header"))),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c("div", { staticClass: "circle-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "circle js-circle",
                attrs: {
                  href: "#",
                  id: "js-circle-relax-a-sport",
                  "data-target": ".js-list-selection-relaxandsport",
                  title: _vm.$t("filter.relax_and_sport.header"),
                },
              },
              [
                _c("span", { staticClass: "item-wrap" }, [
                  _c("span", { staticClass: "icon icon-bike" }),
                  _c("br"),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("filter.relax_and_sport.header"))),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3" }, [
          _c("div", { staticClass: "circle-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "circle js-circle",
                attrs: {
                  href: "#",
                  id: "js-circle-sluzby",
                  "data-target": ".js-list-selection-services",
                  title: _vm.$t("filter.services.header"),
                },
              },
              [
                _c("span", { staticClass: "item-wrap" }, [
                  _c("span", { staticClass: "icon icon-coffee" }),
                  _c("br"),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("filter.services.header"))),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form",
        attrs: { method: "get", action: _vm.$t("filter.button.show_url") },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-gettoknow",
          },
          [
            _c("div", { staticClass: "row row-inner-nicely" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "row row-inner-nicely" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c("h2", { staticClass: "h3" }, [
                        _vm._v(_vm._s(_vm.$t("filter.discover.choose"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row-column" },
                      _vm._l(_vm.discoverTags, function (tag) {
                        return _c("div", { staticClass: "column-item" }, [
                          _c(
                            "div",
                            { staticClass: "form-row form-row-checkbox" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "hover-underline label-with-count",
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      id: tag.slug,
                                      name: tag.slug,
                                      type: "checkbox",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: tag.slug } }, [
                                    _vm._v(_vm._s(tag.name) + " "),
                                    _c("b", [
                                      _vm._v("(" + _vm._s(tag.itemCount) + ")"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-row form-row-submit text-center margin-bottom-0",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: {
                                type: "submit",
                                name: "i",
                                value: "poznavat",
                                title: _vm.$t("filter.button.show"),
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("filter.button.show")))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-relaxandsport",
          },
          [
            _c("div", { staticClass: "row row-inner-nicely" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "row row-inner-nicely" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c("h2", { staticClass: "h3" }, [
                        _vm._v(_vm._s(_vm.$t("filter.relax_and_sport.choose"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row-column" },
                      _vm._l(_vm.relaxAndSportTags, function (tag) {
                        return _c("div", { staticClass: "column-item" }, [
                          _c(
                            "div",
                            { staticClass: "form-row form-row-checkbox" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "hover-underline label-with-count",
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      id: tag.slug,
                                      name: tag.slug,
                                      type: "checkbox",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: tag.slug } }, [
                                    _vm._v(_vm._s(tag.name) + " "),
                                    _c("b", [
                                      _vm._v("(" + _vm._s(tag.itemCount) + ")"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-row form-row-submit text-center margin-bottom-0",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: {
                                type: "submit",
                                name: "i",
                                value: "relax-a-sport",
                                title: _vm.$t("filter.button.show"),
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("filter.button.show")))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "content-wrapper margin-bottom-20-t-10 list-selection js-list-selection js-list-selection-services",
          },
          [
            _c("div", { staticClass: "row row-inner-nicely" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "row row-inner-nicely" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c("h2", { staticClass: "h3" }, [
                        _vm._v(_vm._s(_vm.$t("filter.services.choose"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row-column" },
                      _vm._l(_vm.servicesTags, function (tag) {
                        return _c("div", { staticClass: "column-item" }, [
                          _c(
                            "div",
                            { staticClass: "form-row form-row-checkbox" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "hover-underline label-with-count",
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      id: tag.slug,
                                      name: tag.slug,
                                      type: "checkbox",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: tag.slug } }, [
                                    _vm._v(_vm._s(tag.name) + " "),
                                    _c("b", [
                                      _vm._v("(" + _vm._s(tag.itemCount) + ")"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-row form-row-submit text-center margin-bottom-0",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: {
                                type: "submit",
                                name: "i",
                                value: "sluzby",
                                title: _vm.$t("filter.button.show"),
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("filter.button.show")))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }