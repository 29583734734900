var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "row row-inner-nicely" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "a",
            {
              class: _vm.getTipsHeaderClass("Top místa"),
              attrs: {
                href: "#",
                "data-target": ".js-list-tips-places",
                title: _vm.$t("top_places.header"),
              },
            },
            [
              _c("h2", { staticClass: "h4" }, [
                _vm._v(_vm._s(_vm.$t("top_places.header"))),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "a",
            {
              class: _vm.getTipsHeaderClass("Aktuality"),
              attrs: {
                href: "#",
                "data-target": ".js-list-tips-news",
                title: _vm.$t("news.header"),
              },
            },
            [
              _c("h2", { staticClass: "h4" }, [
                _vm._v(_vm._s(_vm.$t("news.header"))),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.getContentClass("Top místa") }, [
          _c(
            "div",
            { staticClass: "row align-content-stretch row-inner-nicely" },
            [
              _vm._l(_vm.featuredItems, function (item) {
                return _vm.featuredItems.length > 0
                  ? _c(
                      "div",
                      { staticClass: "col-sm-4 col-md-4 margin-top-20-t-10" },
                      [
                        _c("div", { staticClass: "box" }, [
                          item.mainImage.formats !== undefined
                            ? _c("div", [
                                _c("a", {
                                  staticClass: "image-cover",
                                  style: _vm.getItemImageBackground(item),
                                  attrs: {
                                    href: "/detail/" + item.slug,
                                    title: item.mainImage.formats[3].alt,
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "box-content" }, [
                            _c("div", { staticClass: "box-text-wrap" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "header",
                                  attrs: {
                                    href:
                                      _vm.localePath + "/detail/" + item.slug,
                                    title: item.name,
                                  },
                                },
                                [
                                  _c("h2", { staticClass: "h5 weight-600" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              item.parent.dateFrom
                                ? _c("p", { staticClass: "date" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(item.parent.dateFrom)),
                                    ]),
                                    item.parent.dateTo
                                      ? _c("b", [
                                          _vm._v(
                                            " - " + _vm._s(item.parent.dateTo)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "perex",
                                domProps: { innerHTML: _vm._s(item.perex) },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.featuredItems.length === 0
                ? _c("div", { staticClass: "col-sm-12 margin-top-20-t-10" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("top_places.not_found")))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.featuredItems.length > 0
                ? _c("div", { staticClass: "col-sm-12 margin-top-20-t-10" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          attrs: {
                            href: _vm.$t("top_places.more_url"),
                            title: _vm.$t("top_places.more"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("top_places.more")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.getContentClass("Aktuality") }, [
          _c(
            "div",
            { staticClass: "row align-content-stretch row-inner-nicely" },
            [
              _vm._l(_vm.latestNews, function (news) {
                return _vm.latestNews.length > 0
                  ? _c(
                      "div",
                      { staticClass: "col-sm-4 col-md-4 margin-top-20-t-10" },
                      [
                        _c("div", { staticClass: "box" }, [
                          news.mainImage.formats !== undefined
                            ? _c("div", [
                                _c("a", {
                                  staticClass: "image-cover",
                                  style: _vm.getItemImageBackground(news),
                                  attrs: {
                                    href:
                                      _vm.localePath + "/detail/" + news.slug,
                                    title: news.mainImage.formats[3].alt,
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "box-content" }, [
                            _c("div", { staticClass: "box-text-wrap" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "header",
                                  attrs: {
                                    href:
                                      _vm.localePath + "/detail/" + news.slug,
                                    title: news.name,
                                  },
                                },
                                [
                                  _c("h2", { staticClass: "h5 weight-600" }, [
                                    _vm._v(_vm._s(news.name)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "perex",
                                domProps: { innerHTML: _vm._s(news.perex) },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.latestNews.length === 0
                ? _c(
                    "div",
                    { staticClass: "col-sm-4 col-md-4 margin-top-20-t-10" },
                    [_c("p", [_vm._v(_vm._s(_vm.$t("news.not_found")))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.latestNews.length > 0
                ? _c("div", { staticClass: "col-sm-12 margin-top-20-t-10" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          attrs: {
                            href: _vm.$t("news.more_url"),
                            title: _vm.$t("news.more"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("news.more")))]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }